<template>
  <div ref="pageBox">
    <SearchBox ref="SearchBox">
      <el-form slot="form" :inline="true" label-width="80px" class="unitWidth" size="small">
        <el-form-item label="标题">
          <el-input v-model="query.title" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="互助类型">
          <el-select v-model="value" @change="hanledatapicker" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <template slot="btns">
        <el-button size="small" @click="selrele()" type="primary">搜索</el-button>
      </template>
    </SearchBox>
    <LabelContent title="互助内容审核" ref="LabelContent"></LabelContent>
    <div class="tableBox">
      <el-table
        stripe
        v-loading="loading"
        ref="multipleTable"
        :data="tableData"
        class="center"
        :height="tableHeight + 'px'"
        :default-sort="{ prop: 'value', order: 'descending' }"
      >
        <el-table-column prop="articlesLabel.desc" label="互助类型" :sort-method="sortByDate" />
        <el-table-column prop="creatorName" label="发布人" :sort-method="sortByDate" />
        <el-table-column prop="createTime" label="发布时间" :sort-method="sortByDate" />
        <el-table-column prop="content" label="互助内容" show-overflow-tooltip />
        <el-table-column label="互助图片/视频" show-overflow-tooltip>
          <template slot-scope="slot">
            <el-image
              style="width: 50px; height: 50px"
              v-if="slot.row.hasOwnProperty('files')"
              :preview-src-list="[slot.row.files[0].updUrl]"
              :src="slot.row.files[0].updUrl"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="地址信息" show-overflow-tooltip />
        <el-table-column prop="auditStatus.desc" label="审核状态" />
        <el-table-column prop="isTop" label="是否置顶">
          <template slot-scope="slot">{{ slot.row.isTop ? '是' : '否' }}</template>
        </el-table-column>
        <el-table-column label="操作" width="130">
          <template slot-scope="scope">
            <el-button type="primary" @click="tableDetail(scope.row)">审核</el-button>
            <!-- <el-button type="primary" @click="Placedtop(scope.row)">置顶</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="paginationChange"
        @current-change="paginationChange"
        :current-page="query.pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totol"
      />
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      totol: 0,
      loading: false,
      tableHeight: 0, //表格高度
      tableData: [],
      dialogFormVisible: false,
      query: {
        articlesLabel: "",
        auditStatus: "",
        pageNum: 1,
        pageSize: 10,
        timeStart: "",
        timeEnd: "",
        title: ""
      },
      options: [{
        value: 'business',
        label: '商业'
      }, {
        value: 'life',
        label: '生活'
      }],
      value: ''
    }
  },
  mounted () {
    this.loading = true
    //设置表格高度
    this.$nextTick(() => {
      let page = this.$refs.pageBox.offsetHeight || 0 //卡片高度
      let SearchBox = this.$refs.SearchBox
        ? this.$refs.SearchBox.$el.offsetHeight
        : 0 //搜索框高度
      let LabelContent = this.$refs.LabelConten
        ? this.$refs.LabelContent.$el.offsetHeight
        : 0 //内容标题高度
      this.tableHeight = page - (SearchBox + LabelContent + 20 + 30) //padding -20,分页 -30
    })
    this.init()
  },
  methods: {
    //查询 
    selrele () {
      this.init()
    },
    //互助信息列表数据
    async init () {
      let that = this
      let result = await that.$http.request({
        url: "/hw/admin/articles/mi/page",
        method: "GET",
        params: that.query,
      })
      if (result.status == 100) {
        let { data } = result
        that.tableData = data.list
        that.total = data.total
        that.loading = false
      }
    },
    //下拉框搜索
    hanledatapicker () {
      this.query.articlesLabel = this.value
      this.init()
    },
    //置顶
    // async Placedtop (row) {
    //   const _this = this
    //   this.$confirm("是否置顶？", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "primary",
    //   }).then(async () => {
    //     this.dialogFormVisible = true
    //     let result = await this.$http.request({
    //       url: "/hw/admin/articles/top_mutual/" + this.query.artId,
    //       method: "POST",
    //       params: {
    //         auditStatus: "success"
    //       }
    //     })
    //     this.$message("置顶成功！")
    //     this.init()
    //   })
    // },
    //分页
    paginationChange (val) {
      console.log(val)
    },
    //表格行过滤
    formatterRow (row, column) { },
    //从后台获取数据,重新排序
    sortChange (val) {
      // 详细文档参考： https://element.eleme.cn/2.13/#/zh-CN/component/table
      console.log(val)
    },
    //自定义排序规则 sort-method
    sortByDate (a, b) {
      console.log(a, b)
      if (a < b) return -1
    },

    //跳转审核
    tableDetail (row) {
      this.$router.push({
        path: "/Help_details/" + row.artId,
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.el-button--primary {
  width: 4.5vw;
  height: 2vw;
  line-height: 0px;
}
.stywid {
  margin-left: 1vw;
}
.stywid1 {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin-top: 2.5vw;
}
</style>
